<template>
  <div class="gallery container">
    <h1>Captured by my camera</h1>
    <div class="row">
      <div class="col-md-12 mt-3 mb-1">
        <h2 class="mt-5 project-title">Ongoing projects:</h2>

        <div class="row equalHeights">
          <div class="col-md-6">
            <div class="bg-light text-dark p-5 serif project-data">
              <h3 class="serif mb-3">In the shadow of the mining tower</h3>
              <img src="/images/gruvlave.jpg" class="img-fluid mb-4" alt="" />

              <p>
                I want to document rural Sweden and its depopulation in the small mining towns.<br />
                Visit places that were once soaring, but that are now just a shadow of their former self. Left erect are often
                the towers from the mines. (gruvlavar).
              </p>
              <p>
                I want to visit the villages that housed big mines. I want to visit the small towns where thousands have moved
                out in the last 20 years. I want to visit the places that are about to be forgotten.
              </p>
              <p>
                The process has been so fast that the people affected are still alive. The workers from the mine previously shut
                down. The postal worker from the postal office that closed. And I want to document them and their places. To
                pass on their stories to coming generations.
              </p>
              <p>Their legacy is too important not to be told.</p>
              <p><strong>Timeframe: </strong> Whole 2022 and 2023</p>
              <p><strong>Current status: </strong> Planning</p>
            </div>
          </div>
          <div class="col-md-6 mt-sm-5 mt-md-0">
            <div class="bg-light text-dark p-5 serif project-data">
              <h3 class="serif mb-3">100 strangers street photo project</h3>
              <img src="/images/raggarunt.jpg" class="img-fluid mb-4" />

              <p>
                Having recently taken up analog photo I felt like I needed a analog project. And what better than shooting
                complete strangers?
              </p>
              <p>The 100 strangers project is about strangers on the street, and simply taking their portraits.</p>
              <p>
                The whole project is shot on old semi-automatic cameras like the <i>Olympus Trip 35</i> ,
                <i>Olympus Mju - I</i> and <i>Konica C35</i>
              </p>
              <p><strong>Timeframe: </strong> Ongoing</p>
              <p><strong>Current status: </strong> Shooting</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h2 class="mt-5 project-title">Unsorted images:</h2>

    <div class="row mt-5">
      <div class="col-md-12">
        <div class="row">
          <div v-for="(image, index) in images" v-bind:key="image.title" class="mb-4" :class="getImageSizeClass(image.size)">
            <img :src="image.url" class="img-fluid bordered" :title="image.title" :alt="image.title" />
          </div>
        </div>
      </div>
    </div>
    <div class="m-auto text-center">
      <a href="https://instagram.com/larsemil" target="_blank">
        <gb-social-button network="instagram" theme="dark" class="m-auto"> More images on instagram </gb-social-button></a
      >
    </div>
  </div>
</template>

<script>
var masonry = null
export default {
  mounted: function () {},
  methods: {
    getImageSizeClass: function (size = 'normal') {
      return this.sizeMapping[size] || 'col-md-6'
    }
  },

  data: function () {
    return {
      sizeMapping: {
        small: 'col-md-4',
        normal: 'col-md-6',
        big: 'col-md-12'
      },
      images: [
        {
          url: 'images/island.jpg',
          title: 'Foggy island',
          description: '',
          position: 'center center',
          size: 'big'
        },
        {
          url: 'images/i_kea.jpg',
          title: 'Tired of shoping',
          description: '',
          position: 'center center',
          size: 'big'
        },
        {
          url: 'images/emil_skog.jpg',
          title: 'Self portrait',
          description: '',
          position: 'center center',
          size: 'test'
        },

        {
          url: 'images/hedda.jpg',
          title: 'Into the forest',
          description: 'Gathering mushroomss',
          position: 'center center'
        },

        {
          url: 'images/mayavilma.jpg',
          title: 'The girls',
          description: '',
          position: 'center center',
          size: 'big'
        },
        {
          url: '/images/lift.jpg',
          title: 'A cat agaaain',
          description: 'What is internet without a second cat?',
          position: 'center 0px'
        },
        {
          url: '/images/nakenfis.jpg',
          title: 'Self portrait, aka 40 year crisis',
          description: 'What is internet without a second cat?',
          position: 'center -100px'
        },
        {
          url: 'images/wedding.jpg',
          title: 'Wedding',
          description: '',
          position: 'center center',
          size: 'big'
        },
        {
          url: 'images/wedding2.jpg',
          title: 'Wedding2',
          description: '',
          position: 'center center'
        },
        {
          url: 'images/wedding3.jpg',
          title: 'Wedding3',
          description: '',
          position: 'center center'
        },
        {
          url: '/images/eskil.jpg',
          title: 'My son',
          description: '',
          position: 'center center',
          size: 'big'
        },
        {
          url: '/images/kimberly.jpg',
          title: 'My neighbour',
          description: '',
          position: 'center center',
          size: 'small'
        },
        {
          url: '/images/maja.jpg',
          title: 'My love',
          description: '',
          position: 'center center',
          size: 'small'
        },
        {
          url: '/images/svlave.jpg',
          title: 'Mining tower',
          description: '',
          position: 'center center',
          size: 'small'
        },
        {
          url: '/images/vilma.jpg',
          title: 'Bonus daughter',
          description: 'What is internet without a second cat?',
          position: 'center center'
        },
        {
          url: 'images/viola.jpg',
          title: 'Viola',
          description: '',
          position: 'center center'
        },
        {
          url: 'images/ahmed.jpg',
          title: 'Ahmed',
          description: '',
          position: 'center center',
          size: 'small'
        },
        {
          url: 'images/close.jpg',
          title: 'Close up',
          description: '',
          position: 'center center',
          size: 'small'
        },
        {
          url: 'images/shred.jpg',
          title: 'Trees',
          description: '',
          position: 'center center',
          size: 'small'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.gallery {
  .project-data {
    height: 100%;
  }
  .project-title {
    margin-top: 20px !important;
    margin-bottom: 20px;
  }
  .bordered {
    background-color: #fff;
    padding: 20px;
  }

  .image {
    display: flex;
    background-color: #fff;
    padding: 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    border: 10px solid transparent;
    background-clip: padding-box;
    height: 350px;

    .image-background {
      background-size: cover;
      background-repeat: no-repeat;

      width: 100%;
      height: 100%;
    }
  }
}
</style>
