import { render, staticRenderFns } from "./About.vue?vue&type=template&id=15fa0ce0&scoped=true&"
var script = {}
import style0 from "./About.vue?vue&type=style&index=0&id=15fa0ce0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15fa0ce0",
  null
  
)

export default component.exports